import { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"
import { PatternFormat } from "react-number-format"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import classNames from "classnames"
import { Form } from "react-bootstrap"
import { errorsVar, isLoggedInVar } from "cache"
import RSVPConfig from "RSVPConfig"
import Hero from "components/shared/Hero"
import ErrorToast from "components/shared/ErrorToast"
import { AUTH_KEY, AUTH_PATHS } from "shared/constants"
import { sisterSiteRedirect } from "shared/helpers/url"
import { useAuthRedirectEffect } from "components/Login/hooks"
import FullPageLoader from "components/shared/FullPageLoader"
import infoIcon from "components/ParkingSelection/assets/info.svg"
import { Signup as SignUpUser } from "./operations"

import styles from "./Signup.module.css"

const Signup = () => {
  useAuthRedirectEffect()
  const history = useHistory()
  const [t] = useTranslation()
  const { features } = RSVPConfig

  const onCompleted = ({ signup }) => {
    const oaTag = get(signup, ["userSession", "oaTag"])
    const { errors } = signup

    if (!isEmpty(errors)) {
      if (errors.includes("email_address_redirect")) {
        history.push("/login")
      }
      errorsVar(errors)
    }

    if (oaTag) {
      localStorage.setItem(AUTH_KEY, oaTag)
      errorsVar(null)
      isLoggedInVar(true)

      // Signup to a sister site
      sisterSiteRedirect(history, AUTH_PATHS.login)
    }
  }

  const [signupUser, { loading }] = useMutation(SignUpUser, {
    onCompleted
  })

  const [emailAddress, setEmailAddress] = useState("")
  const [password, setPassword] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [validated, setValidated] = useState(false)

  const renderPhoneNumberError = (phone) => {
    if (isEmpty(phone)) {
      return "Phone number is required."
    }
    return "Please enter a valid phone number."
  }

  const handleSubmit = (e) => {
    const form = e.target

    e.preventDefault()
    if (form.checkValidity() === false) {
      e.stopPropagation()
    } else {
      signupUser({
        variables: {
          input: { emailAddress, password, phoneNumber }
        }
      })
    }
    setValidated(true)
  }

  if (loading) return <FullPageLoader message="Creating your account..." />

  return (
    <>
      <Helmet title="Sign Up" />
      <Hero hideLogin>
        <Hero.Headline>
          Create an Account
          <br />
          to Continue
        </Hero.Headline>
      </Hero>

      <div className={classNames(styles.loginLine, "text-center")}>
        Are you a returning user?&nbsp;
        <Link to="/login" replace>
          Log In
        </Link>
      </div>

      <div className={classNames(styles.formWrapper, "container-sm")}>
        <div className="row justify-content-center">
          <div className="col-md-8 col-xl-6">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="emailAddress" className="form-label fw-bold">
                  Email address
                </label>
                <input
                  id="emailAddress"
                  className="form-control"
                  placeholder="Enter your email address"
                  value={emailAddress}
                  type="email"
                  required
                  onChange={(e) => {
                    setEmailAddress(e.target.value)
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email address.
                </Form.Control.Feedback>
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label fw-bold">
                  Password
                </label>
                <input
                  id="password"
                  className="form-control"
                  placeholder="Enter your password"
                  type="password"
                  value={password}
                  required
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid password.
                </Form.Control.Feedback>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="phoneNumber"
                  className="form-label fw-bold d-flex align-items-center"
                >
                  Phone Number
                  {!features.userPhoneNumber && (
                    <div className="ps-1 fw-normal text-muted fs-6 fst-italic">
                      (Optional)
                    </div>
                  )}
                </label>
                <PatternFormat
                  id="phoneNumber"
                  displayType="input"
                  className="form-control"
                  placeholder="Enter your phone number"
                  format="(###) ### - ####"
                  mask="_"
                  value={phoneNumber}
                  required={features.userPhoneNumber}
                  pattern="^\(\d{3}\) \d{3} - \d{4}$"
                  onChange={(e) => {
                    setPhoneNumber(e.target.value)
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {renderPhoneNumberError(phoneNumber)}
                </Form.Control.Feedback>
              </div>
              <div className="d-grid">
                <button
                  type="submit"
                  className={classNames(
                    styles.submitButton,
                    "btn btn-primary btn-lg mt-3 mb-4 fw-bold"
                  )}
                >
                  Create Account
                </button>
              </div>
            </Form>
            <div className={classNames(styles.infoCard, "mb-4")}>
              <div className="d-flex align-items-center mb-3">
                <img src={infoIcon} className="me-1" alt="info" />
                <h3 className="fw-bold">More Information</h3>
              </div>
              <div className={styles.infoText}>
                <div className="fw-bold d-block mb-1">
                  {t("phoneNumber.header")}
                </div>
                <div className={styles.infoAnswer}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t("phoneNumber.text")
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorToast />
    </>
  )
}

export default Signup
